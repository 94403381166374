import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowPopupShow: false
  },
  mutations: {
    changeIsShowPopupShow(state, payload) {
      state.isShowPopupShow = payload
    }
  },
  actions: {},
  modules: {}
})