// 清除缓存数据
const version = require('../package.json').version
const versionStorage = localStorage.getItem('version')
if (version != versionStorage) {
  localStorage.clear()
  localStorage.setItem('version', version)
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/vantui"
import "reset-css"
import '@/components/vant.js'
import VueRouter from 'vue-router'
import {getEdition,loginTime} from "@/request/api";

// 手机适配
import 'amfe-flexible';
import 'amfe-flexible/index.js';

import MetaInfo from 'vue-meta-info'
//vant
import VueClipBoard from 'vue-clipboard2'
import { Toast,Loading,Overlay  } from 'vant';
// echarts
import * as echarts from 'echarts'
import VueTouch from 'vue-touch'

Vue.prototype.$echarts = echarts
Vue.use(VueTouch,{name:'v-touch'})

Vue.use(Toast);
Vue.use(Loading);
Vue.use(Overlay);

Vue.prototype.getUserDetail = function (){
  return JSON.parse(localStorage.getItem("userInfo"));
}

Vue.use(VueClipBoard)

Vue.use(MetaInfo);
Vue.use(VueRouter);
Vue.config.ignoredElements = [...Vue.config.ignoredElements, 'wx-open-launch-weapp']

// const app = Vue.use(App);
// console.log(app);
// console.log(app.config.globalProperties);
// import Video from 'video.js'
// import 'video.js/dist/video-js.css'
// Vue.prototype.$video = Video


// import Vue from 'vue'
// import VideoPlayer from 'vue-video-player/src'
// import 'video.js/dist/video-js.css'
// require('vue-video-player/node_modules/video.js/dist/video-js.css') 
// require('vue-video-player/src/custom-theme.css') 
// Vue.use(VideoPlayer)

// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
// import VideoPlayer from 'vue-video-player/src';
// import 'vue-video-player/src/custom-theme.css';
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)

import videoPlayer  from 'vue-video-player';
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(videoPlayer );

Vue.prototype.getToken = function (){
  return localStorage.getItem("token");
}
Vue.prototype.getBranch = function (){
  let branch = localStorage.getItem("branchId");
  if(branch == 'null' || branch == '') {
    return 1;
  }
  return (branch ? branch : 1);
}
Vue.prototype.getGroupId= function (){
  return localStorage.getItem("groupId");
}
Vue.prototype.getSubjectId = function (){
  return localStorage.getItem("subjectId");
}
Vue.prototype.getAdminId = function (){
  let adminId = localStorage.getItem("adminId");
  if(adminId == 'null' || adminId == '') {
    return 1;
  }
  return (adminId ? adminId : 1);
}
Vue.prototype.getUserInfo = function (){
  return JSON.parse(localStorage.getItem("userinfo"));
}
Vue.prototype.getBranchCompany = function (){
  let title = localStorage.getItem("title");
  if(title == 'null' || title == '' || title == 'undefined') {
    return '手机网校';
  }
  return (title ? title : '手机网校')
}
Vue.prototype.getStrNumber = function (number){
  var numReg = /^[0-9]*$/
  var numRe = new RegExp(numReg)
  console.log(numRe.test(number));
  if (!numRe.test(number)) {
    return false
  }
  return true;
}
Vue.prototype.getUrlParameter = function (){
  let data = location.href.split('/')
  let len = data.length;
  let num = data[len-1];
  var numReg = /^[0-9]*$/
  var numRe = new RegExp(numReg)
  let url = location.href;
  if (!numRe.test(num)) {
    url = location.href+'/'+this.getBranch();
  }
  console.log(url);
  return url;
}
Vue.prototype.getAppLogin = function (){
  let type = localStorage.getItem("appLogin");
  if(type) {
    return true;
  }
  return false;
}
Vue.prototype.getIcoUrl = function (icoUrl){
  var link= document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = icoUrl
  document.getElementsByTagName('head')[0].appendChild(link);
}
window.getEdition = function() {
  console.log('edition');

  getEdition()
      .then(res => {
        if(res.data.code == 1) {
          localStorage.setItem('edition',res.data.data.edition)
        }
      })
      .catch(err => {
        localStorage.setItem('edition',1.0)
      })
  var  edition = localStorage.getItem('edition')
  return edition;
}

Vue.config.ignoredElements = [
  'wx-open-launch-weapp',
]
Vue.prototype.isWeiXin = function(){
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if(ua.match(/MicroMessenger/i) == 'micromessenger'){
    return true;
  } else{
    return false;
  }
}
Vue.prototype.loginTimer = null;
Vue.prototype.loginTime = function(){
  console.log('登录验证');
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if(ua.match(/MicroMessenger/i) != 'micromessenger'){
    if(window.android != undefined) {
      if(window.android.isapp() == true) {
        return false;
      }
    }
  }

  let that = this;
  if(that.getToken()) {
    that.loginTimer = setInterval(() => {
      console.log('调用定时');
      loginTime()
          .then(res => {
            console.log(res.data,'在线时间过期');
            if(res.data.code == 401) {

              Toast.fail(res.data.msg);
              //清除登录 重新登录
              localStorage.removeItem("token");
              localStorage.removeItem("userInfo");
              clearInterval(that.loginTimer)
              window.parent.location.reload();
              window.location.href='#/user'
              console.log('在线时间过期1');
            }
          })
          .catch(err => {
            console.log('在线时间过期2');
            Toast.fail('已过期，请重新登陆');
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            clearInterval(that.loginTimer)
            window.parent.location.reload();
            window.location.href='#/user'
          })
    }, 60000)
  }
}





Vue.filter("RMBformat", val => {
  return "¥" + Number(val).toFixed(2) + "元"
})

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
