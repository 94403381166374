<template>
  <div id="app">
    

    <AppTabbar v-show="$route.meta.isShowTabbar"/>
<!--    <router-view/>-->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import AppTabbar from '@/components/AppTabbar'

export default {
  components:{
    AppTabbar
  },
  globalData: {

  },
  provide (){
    return {
      reload:this.reload
    }
  },
  data(){
    return {
      isRouterAlive:true
    }
  },
  methods:{
    reload (){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  },
}
</script>

<style lang="less">
@import '~@/assets/styles/common.less';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  min-height: 100%;
  font-size: .14rem ;
}
html,body {
  height: 100%;
}
html {
  font-size: 100px !important;   // 16px?? 
}



</style>
